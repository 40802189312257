<template>
  <cs-dialog
    ref="dialog"
    width="436px"
    :submit="null"
    class="com-system-OtherLogin"
  >
    <template slot="title">
      <div style="padding: 5px">
        <cs-icon name="icon-yiwen" class="text-warning" />
        <span class="fw-bold" style="margin-left: 16px; font-size: 16px"
          >提示</span
        >
      </div>
    </template>
    <div class="text-center">
      <p>您的账号已在其它地方登录，请选择处理方式</p>
      <div style="margin-top: 50px">
        <a-button type="primary" @click="submitClick(2)">保留其它登录</a-button>
        <a-button
          type="primary"
          @click="submitClick(1)"
          style="margin-left: 24px"
          >退出其它登录</a-button
        >
      </div>
    </div>
  </cs-dialog>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    async submitClick(type) {
      if (type == 1) {
        await this.api.service.uac_oapi_sso_removeOtherLogin();
      }
      this.$refs.dialog.done();
    },
  },
};
</script>
<style lang="less" scoped>
.com-system-OtherLogin {
  /deep/ .ant-modal {
    .ant-modal-header {
      border-bottom: none;
      padding-bottom: 15px;
    }
    .ant-modal-footer {
      border-top: none;
      padding: 24px;
    }
    .ant-modal-body {
      padding: 0;
    }
    .dialog-btn-cancel {
      display: none;
    }
  }
}
</style>
